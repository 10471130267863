<template>
  <ProductRecoShopTheImage
    :products-meta="productsMeta"
    :products="products"
    :positions="props?.content?.config?.positions?.value"
    :media="updatedMedia"
    @add-to-cart="
      addProduct({
        id: $event.id,
        quantity: 1,
      })
    "
  />
</template>

<script setup lang="ts">
import type { CmsElement } from "@/types/cms";
import type { Media } from "@shopware-pwa/types";
import type { Position } from "@/components/organisms/ProductRecoShopTheImage/ProductRecoShopTheImage.interface";
import ProductRecoShopTheImage from "@/components/organisms/ProductRecoShopTheImage";
import { getProductName, getProductRoute } from "@shopware-pwa/helpers-next";
import type { Schemas } from "#shopware";

const props = defineProps<{
  content: CmsElement<{
    products: string[];
    positions: Position[];
    media: Media;
  }>;
}>();

const { addProduct } = useCartWithSideEffects();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { getFormattedPrice } = usePrice();

interface ElementData {
  media: Media;
  products: Schemas["Product"][];
}

const elementData = props.content.data as ElementData;

const updatedMedia = elementData?.media;

const products = props.content.config.products.value.map((productId) => {
  return elementData.products.find((product) => product.id === productId);
});

const productsMeta = products.map((product) => {
  return {
    name: product ? getProductName({ product }) : null,
    formattedPrice: {
      unitPrice: product
        ? getFormattedPrice(product?.calculatedPrice?.unitPrice)
        : null,
      listPrice: product
        ? getFormattedPrice(product.calculatedPrice?.listPrice?.price)
        : null,
      readablePricePerUnit: product
        ? `(${getFormattedPrice(
            product.calculatedPrice?.referencePrice?.price,
          )}/${product.unit?.translated?.shortCode})`
        : null,
    },
    formattedRoute: product ? formatLink(getProductRoute(product)) : null,
  };
});
</script>
